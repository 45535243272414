<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">AVISO</h2>
			<p class="text-format-2x text-center px-3">
				¡Vaya! Tu documento de identidad parece estar caducado. Lo sentimos mucho pero
				necesitamos un documento de identidad vigente para poder continuar con el proceso.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click="reload"
				class="button-confirm mb-5 txt-uc"
				type="button"
			>
				ABANDONAR EL PROCESO
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-expired-id',

	methods: {
		reload() {
			window.document.location.reload();
		},
	},
};
</script>
