<template>
	<div id="app-personal-data">
		<div
			class="app-personal-data__selection-modal"
			v-if="!collective"
		>
			<h1 class="text-center text-format-1">
				Por favor, selecciona el colectivo del que quieres ser cliente
			</h1>
			<div class="collectives">
				<div class="collective">
					<button
						class="collective-button"
						@click="selectCollective(1)"
					>
						<img
							class="collective-img"
							alt
							src="@/assets/images/eng.png"
						/>
						<div class="collective-desc">
							<h2 class="text-format-2 font-weight-bold">CBNK Ingeniería</h2>
							<span class="text-format-2 align-items-center">
								Soluciones financieras a medida
								<br />
								para
								<a
									href="#"
									class="collective-eng"
								>
									ingenieros y empresas de
									<br />
									ingeniería
								</a>
							</span>
							<img
								src="@/assets/images/logo-eng.svg"
								alt="logo-eng"
								width="40%"
								height="30%"
							/>
						</div>
					</button>
				</div>
				<div class="collective">
					<button
						class="collective-button"
						@click="selectCollective(2)"
					>
						<img
							class="collective-img"
							alt
							src="@/assets/images/sal.png"
						/>
						<div class="collective-desc">
							<h2 class="text-format-2 font-weight-bold">CBNK Salud</h2>
							<span class="text-format-2 align-items-center">
								Soluciones financieras a medida
								<br />
								para
								<a
									href="#"
									class="collective-sal"
								>
									profesionales de la salud
								</a>
							</span>
							<img
								class="collective-logo"
								src="@/assets/images/logo-sal.svg"
								alt="logo-sal"
								width="40%"
								height="30%"
							/>
						</div>
					</button>
				</div>
			</div>
		</div>
		<div v-else>
			<call-help></call-help>
			<app-stepper :step-number="1"></app-stepper>
			<div class="row flex-column justify-content-center align-items-center mainBox">
				<h2 class="col-11 col-xl-8 mb-4 text-center text-format-1">
					Por favor {{ clientName }}, revisa y completa tus datos personales. Estás muy cerca
					de formar parte de {{ projectName }}
				</h2>
				<form class="d-flex justify-content-center flex-column align-items-center">
					<div class="row d-flex justify-content-center flex-column flex-md-row w-100">
						<div class="col">
							<div class="form-group">
								<label
									:class="{ invalidForm: nameError }"
									for="name"
								>
									*Nombre
								</label>
								<input
									id="name"
									class="form-control"
									:class="{ invalidInfoBox: nameError }"
									v-model.trim="name"
									placeholder
									type="text"
									autocomplete="given-name"
									@blur="$v.name.$touch()"
								/>
								<div class="space-error">
									<span
										v-if="nameError"
										class="mt-2 invalidInfo help-block"
									>
										{{ nameError }}
									</span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label
									:class="{ invalidForm: surname1Error }"
									for="surname1"
								>
									*Primer apellido
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: surname1Error }"
									id="surname1"
									v-model.trim="surname1"
									@blur="$v.surname1.$touch()"
									placeholder
									type="text"
									autocomplete="family-name"
								/>
								<div class="space-error">
									<span
										v-if="surname1Error"
										class="mt-2 invalidInfo help-block"
									>
										{{ surname1Error }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="row d-flex justify-content-center flex-column flex-md-row w-100">
						<div class="col">
							<div class="form-group">
								<label
									:class="{
										'invalidForm': surname2Error,
										'--is-required': !isSurname2Optional,
									}"
									for="surname2"
								>
									Segundo apellido
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: surname2Error }"
									id="surname2"
									v-model.trim="surname2"
									@blur="$v.surname2.$touch()"
									placeholder
									type="text"
								/>
								<div class="space-error">
									<span
										v-if="surname2Error"
										class="mt-2 invalidInfo help-block"
									>
										{{ surname2Error }}
									</span>
								</div>
							</div>
						</div>

						<div class="col">
							<div :class="{ 'form-row': false }">
								<div
									v-if="false"
									class="form-group col-4"
								>
									<label
										:class="{ invalidForm: documentTypeError }"
										for="numero_documento"
									>
										*Tipo de Documento
									</label>
									<select
										v-model.trim="$v.documentType.$model"
										class="form-control"
										:class="{ invalidInfoBox: documentTypeError }"
										autocomplete="bday-month"
									>
										<option
											disabled
											value="undefined"
										>
											Tipo
										</option>

										<option value="DNI">DNI</option>
										<option value="NIE">NIE</option>
									</select>
									<div class="space-error">
										<span
											v-if="documentTypeError"
											class="mt-2 invalidInfo help-block"
										>
											{{ documentTypeError }}
										</span>
									</div>
								</div>
								<div
									class="form-group"
									:class="{ 'col-8': false }"
								>
									<label
										:class="{ invalidForm: documentNumberError }"
										for="numero_documento"
									>
										*Número Documento
									</label>
									<input
										class="form-control"
										:class="{ invalidInfoBox: documentNumberError }"
										id="numero_documento"
										v-model.trim="documentNumber"
										@change="onDocumentNumberChange"
										@blur="() => (documentNumber = documentNumber.toUpperCase())"
										placeholder
										type="text"
										autocomplete="documentId"
									/>
									<div class="space-error">
										<span
											v-if="documentNumberError"
											class="mt-2 invalidInfo help-block"
										>
											{{ documentNumberError }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center flex-column flex-md-row w-100">
						<div class="col">
							<label
								class="w-100"
								:class="{
									invalidForm:
										ageError ||
										diaNacimientoError ||
										mesNacimientoError ||
										anoNacimientoError,
								}"
								for="dia"
							>
								*Fecha de nacimiento
							</label>
							<div class="form-row justify-content-center">
								<div class="form-group col-3">
									<select
										v-model.number="$v.diaNacimiento.$model"
										class="form-control"
										:class="{ invalidInfoBox: diaNacimientoError }"
										autocomplete="bday-day"
									>
										<option
											disabled
											value="undefined"
										>
											Día
										</option>

										<option
											v-for="day in 31"
											:value="day"
											:key="day"
										>
											{{ day }}
										</option>
									</select>
									<div class="space-error">
										<span
											v-if="
												diaNacimientoError ||
												mesNacimientoError ||
												anoNacimientoError ||
												ageError
											"
											class="mt-2 text-nowrap invalidInfo help-block"
										>
											{{
												ageError ||
												diaNacimientoError ||
												mesNacimientoError ||
												anoNacimientoError
											}}
										</span>
									</div>
								</div>
								<div class="form-group col-5">
									<select
										class="form-control"
										:class="{ invalidInfoBox: mesNacimientoError }"
										v-model.number="$v.mesNacimiento.$model"
										autocomplete="bday-month"
									>
										<option
											disabled
											value="undefined"
										>
											Mes
										</option>

										<option value="1">Enero</option>
										<option value="2">Febrero</option>
										<option value="3">Marzo</option>
										<option value="4">Abril</option>
										<option value="5">Mayo</option>
										<option value="6">Junio</option>
										<option value="7">Julio</option>
										<option value="8">Agosto</option>
										<option value="9">Septiembre</option>
										<option value="10">Octubre</option>
										<option value="11">Noviembre</option>
										<option value="12">Diciembre</option>
									</select>
								</div>
								<div class="form-group col-4">
									<select
										class="form-control select-style"
										:class="{ invalidInfoBox: anoNacimientoError }"
										v-model.number="$v.anoNacimiento.$model"
										autocomplete="bday-year"
									>
										<option
											disabled
											value="undefined"
										>
											Año
										</option>

										<option
											v-for="year in 100"
											:value="currentYear - (year - 1)"
											:key="currentYear - (year - 1)"
										>
											{{ currentYear - (year - 1) }}
										</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label
									:class="{ invalidForm: placeOfBirthError }"
									for="place-of-birth"
								>
									*Población de nacimiento
								</label>
								<input
									id="place-of-birth"
									class="form-control"
									:class="{ invalidInfoBox: placeOfBirthError }"
									v-model.trim="placeOfBirth"
									type="text"
									@blur="$v.placeOfBirth.$touch()"
								/>
								<div class="space-error">
									<span
										v-if="placeOfBirthError"
										class="mt-2 invalidInfo help-block"
									>
										{{ placeOfBirthError }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="confirm-button-group">
						<button
							class="button-confirm mb-5 mt-3 mb-sm-3"
							type="submit"
							:disabled="isButtonDisabled"
							data-testid="confirm-button"
							@click.prevent="submit"
						>
							CONFIRMAR
						</button>
					</div>
					<app-mobile-call></app-mobile-call>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import CallHelp from '@/components/call-help';
import MExpiredId from '@modals/m-expired-id';
import MMinorNotAllowedVue from '@modals/m-minor-not-allowed';
import AppMobileCall from '@/components/app-mobile-call';
import AppStepper from '@/components/app-stepper';
import { alpha } from '@/utils/validators';
import {
	helpers,
	required,
	requiredIf,
	maxLength,
	numeric,
	minValue,
} from 'vuelidate/lib/validators';
import { mapErrorFields } from '@/utils/helpers';
import { mapState } from 'vuex';

const formatDate = (date) => {
	const d = new Date(date);
	let month = `${d.getMonth() + 1}`;
	let day = `${d.getDate()}`;
	const year = d.getFullYear();

	if (month.length < 2) {
		month = `0${month}`;
	}
	if (day.length < 2) {
		day = `0${day}`;
	}

	return [year, month, day].join('-');
};

const getDocumentType = (str) => {
	const DNI_REGEX = /^(\d{8})([A-Z])$/;
	const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

	if (str.match(DNI_REGEX)) {
		return 'DNI';
	}

	if (str.match(NIE_REGEX)) {
		return 'NIE';
	}
};

const projectName = 'CBNK';

export default {
	name: 'app-person-data',

	components: {
		CallHelp,
		AppStepper,
		AppMobileCall,
	},

	data() {
		return {
			projectName,
			name: '',
			surname1: '',
			surname2: '',
			documentType: '',
			documentNumber: '',
			diaNacimiento: null,
			mesNacimiento: null,
			anoNacimiento: null,
			placeOfBirth: '',
			currentYear: new Date().getFullYear(),
			isButtonDisabled: false,
			vertical: null,
			collective: null,
		};
	},

	computed: {
		...mapState('onboarding', ['clientName']),
		...mapErrorFields([
			'name',
			'surname1',
			'surname2',
			'documentType',
			'documentNumber',
			'diaNacimiento',
			'mesNacimiento',
			'anoNacimiento',
			'age',
			'placeOfBirth',
		]),

		age({ diaNacimiento, mesNacimiento, anoNacimiento }) {
			if (!diaNacimiento || !mesNacimiento || !anoNacimiento) {
				return 18;
			}

			const dateToCalculate = new Date();
			const dob = new Date(`${mesNacimiento}/${diaNacimiento}/${anoNacimiento}`).getTime();
			const dateToCompare = new Date(dateToCalculate).getTime();
			const age = Math.floor((dateToCompare - dob) / (365.25 * 24 * 60 * 60 * 1000));

			return age;
		},

		isSurname2Optional({ $v }) {
			return $v.documentType.$model === 'NIE';
		},
	},

	validations: {
		name: {
			alpha,
			required,
			maxLength: maxLength(45),
		},
		surname1: {
			alpha,
			required,
			maxLength: maxLength(45),
		},
		surname2: {
			alpha,
			required: requiredIf(function () {
				return !this.isSurname2Optional;
			}),
			maxLength: maxLength(45),
		},
		documentType: {
			required,
		},
		documentNumber: {
			required,
			validDocumentNumber: helpers.withParams({ type: 'validDocumentNumber' }, function (value) {
				const validChars = 'TRWAGMYFPDXBNJZSQVHLCKE';
				const str = value.toString().toUpperCase();
				const type = getDocumentType(str);
				const letter = str.substr(-1);

				this.documentType = type;

				if (type === 'DNI') {
					const charIndex = parseInt(str.substr(0, 8), 0) % 23;

					return validChars.charAt(charIndex) === letter;
				}

				return false;
			}),
		},
		diaNacimiento: {
			required,
			numeric,
		},
		mesNacimiento: {
			required,
			numeric,
		},
		anoNacimiento: {
			required,
			numeric,
		},
		placeOfBirth: {
			alpha,
			required,
			maxLength: maxLength(35),
		},
		age: {
			minValue: minValue(18),
			numeric,
		},
	},

	methods: {
		onDocumentNumberChange() {
			this.$v.documentNumber.$touch();
		},
		selectCollective(selection) {
			if (selection === 1) {
				this.$store.dispatch('setIsEng');
			} else if (selection === 2) {
				this.$store.dispatch('setIsSal');
			}
			this.vertical = selection;
			this.collective = selection;
		},
		submit() {
			const { $v, $router } = this;
			const {
				name,
				surname1,
				surname2,
				documentType,
				documentNumber,
				diaNacimiento,
				mesNacimiento,
				anoNacimiento,
				placeOfBirth,
				vertical,
				collective,
			} = this;

			$v.$touch();

			if (!$v.$invalid) {
				this.isButtonDisabled = true;
				this.$store
					.dispatch('onboarding/saveProcess', {
						name,
						surname1,
						surname2,
						idDocument: {
							type: documentType,
							id: documentNumber,
						},
						dateOfBirth: formatDate(`${mesNacimiento}/${diaNacimiento}/${anoNacimiento}`),
						placeOfBirth,
						vertical,
						collective,
					})
					.then(() => {
						$router.push('app-address');
					})
					.catch(() => {})
					.finally(() => {
						this.isButtonDisabled = false;
					});
			}
		},
	},

	watch: {
		age() {
			this.$v.age.$touch();
		},

		collective: {
			immediate: true,
			handler(value) {
				if (!value) {
					if (this.$store.state.app.isENG) {
						this.vertical = 1;
						this.collective = 1;
					} else if (this.$store.state.app.isSAL) {
						this.vertical = 2;
						this.collective = 2;
					}
				}
			},
		},
	},

	mounted() {
		const { data } = this.$store.state.eid;

		if (data.birthdate) {
			const [year, month, day] = data.birthdate.split('-');
			this.diaNacimiento = ~~day; // eslint-disable-line no-bitwise
			this.mesNacimiento = ~~month; // eslint-disable-line no-bitwise
			this.anoNacimiento = ~~year; // eslint-disable-line no-bitwise
		}

		if (this.age < 18) {
			this.$store.dispatch('modal/open', MMinorNotAllowedVue);
		}

		this.name = data.name || '';
		this.surname1 = data.surname1 || '';
		this.surname2 = data.surname2 || '';
		this.placeOfBirth = data.placeOfBirth || '';
		this.documentType = data.documentType || 'DNI'; // By default we set DNI
		this.documentNumber = data.documentNumber || '';

		/**
		 * Los DNI permanentes son marcados con fecha de caducidad
		 * 01/01/99 y erróneamente el sistema los reconoce como 1999
		 * en vez de 9999. Pero son DNI que no caducan.
		 */
		if (data.expiredDate && data.expiredDate !== '1999-01-01') {
			if (new Date(data.expiredDate) <= new Date()) {
				this.$store.dispatch('modal/open', MExpiredId);
			}
		}

		this.$nextTick(() => {
			this.name = this.clientName;
		});
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 20px 0 0;
}
form {
	width: 80%;
}
label {
	white-space: nowrap;
}
option {
	color: #113456 !important;
	font-family: 'Poppins', sans-serif !important;
	background: #fff;
}
.invalidForm {
	color: red;
}
.invalidInfo {
	display: inline-block;
	color: red;
}
.invalidInfoBox {
	border: 1px solid red;
}
.space-error {
	padding: 6px 0;
	position: relative;
}
.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

form {
	width: 100%;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

.form-group label.--is-required::before {
	content: '*';
	margin-right: -3px;
}

.collectives {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 30px;
	gap: 60px;
}

.collective-desc {
	display: flex;
	flex-direction: column;
	background: $color-surface-dark;
	gap: 16px;
	padding: 18px 12px;
	align-items: flex-start;

	.collective-eng,
	.collective-sal {
		text-decoration: none;
		cursor: default;
	}

	.collective-eng {
		color: #d68b55;
	}

	.collective-sal {
		color: #6fab6f;
	}

	img {
		margin-left: auto;
	}
}

.collective-logo {
	margin-top: 25px;
}

.collective-button {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-align: left;
}

@media screen and (max-width: 767px) {
	.collectives {
		display: flex;
		flex-direction: column;
	}

	.collective-img {
		max-width: 255px;
	}
}
</style>
