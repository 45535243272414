<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">AVISO</h2>
			<p
				v-if="$store.state.onboarding.productId === 'CTATRANS'"
				class="text-format-2x text-center px-3"
			>
				¡Vaya! Parece que no vamos a poder continuar. Según tu documentación eres menor de edad
				y no puedes solicitar cuenta por este cauce. Estaremos encantados de informarte sobre
				cómo abrir tu cuenta transparente siendo menor de edad llamando al 913 10 95 50.
			</p>
			<p
				v-else
				class="text-format-2x text-center px-3"
			>
				¡Vaya! Parece que no vamos a poder continuar. Según tu documentación eres menor de edad
				y no puedes darte de alta por esta vía. Estaremos encantados de informarte sobre cómo
				contratar nuestro productos siendo menor de edad llamando al 913 10 95 50.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click="reload"
				class="button-confirm mb-5 txt-uc"
				type="button"
			>
				ABANDONAR EL PROCESO
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-minor-not-allowed',

	methods: {
		reload() {
			window.document.location.reload();
		},
	},
};
</script>
